.hub-admin {
  padding: 1.25rem calc(1.25rem + 15px) 1.25rem calc(1.25rem + 15px);
}

.adminTab {
  cursor:pointer;
}

.admin {
  overflow-x: hidden;
  .userListGrid {
    min-height: 15rem;
    width: 38rem;
  }

   .ql-editor {
    min-height: 250px;
  }

  .ql-editor-readonly {
    .ql-editor {
      background-color: #e9ecef;
    }
  }

  .pl-2, .px-2 {
    padding-left: 2rem !important;
  }

  .close-Button {
    position: absolute;
    right: 31px;
    margin: 10px;
    z-index: 1;
  }

  .dateHead {
    width: 183px;
    text-align: center;
    margin-top: 10px;
  }

  .messagesGrid {
    height: 25rem;
    width: 100%;
  }

  .managerHead {
    padding: 0 0rem 0 1rem !important;
  }

  .ddEventHead {
    padding: 0 0rem 0 0rem !important;
  }

  .researchDocuments {
    padding: 0 1rem !important;
  }

  .templateHead {
    padding: 0 2rem !important;
  }

  .headers {
    display: inline-flex;
    font-weight: bolder !important;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem 0 0;
    div {
      text-align: center;
      width: 15rem;
      padding: 0rem 4.3rem;
    }
  }

  .headIten {
    margin-left: 55px;
  }

  .inputLine {
    display: inline-flex;
    position: relative;
    left: 35px;
    margin-top: 15px;
    flex-wrap: nowrap;
    .fullInput {
      margin: 0rem 10px;
      width: 183px;
    }
    .lineLabel{
      font-weight: bolder !important;
      padding-top: 8px;
    }
    .inputLabel {
      height: 36px;
      padding: 0.375rem 0.3rem;
      padding-top: 7px;
    }
    .fromDate {
      padding-left: 0.1rem;
      padding-right: 0.05rem;
      max-width: 160px;
    }
    .templateLabel {
      display: none;
    }
    .form-check-input {
      margin-left: -0.5rem;
    }
    .input-group-text  {
      background-color: $blue-2 !important;
    }
  }

  .templateInput {
    height: 36px;
    padding: 0.375rem 0.25rem;
    margin-right: 20px;
    max-width: 183px;
    min-width: 183px;
  }

  .page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .notificationContainer {
    max-width: 1200px !important;
    min-width: 1200px !important;
    .fromDateRow {
      display: inline-flex;
      column-gap: 1rem;
      margin: 0rem 0 0 18.5rem;
     }
  }

  .line {
    max-width: 90%;
    margin-left: 4%;
    margin-top: 20px;
    border-top: 1px solid #dee2e6;
  }

  .testGroup {
    margin-bottom: 10px;
    .emailLabel {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 8px;
      width: 15rem;
    }
    .emailInput {
      max-width: 360px;
      margin: 0 25px;
    }
    .emailGroup {
      > div {
        display: inline-flex;
        margin: 0.25rem 0;
      }
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 20px;
      left: 20px;
    }
    .testButton {
      background-color: $blue-2;
      margin-left: 1.5rem;
    }
    .testButton:disabled {
      background-color: $gray-2;
    }
    .sended-message {
      margin-left: 1.5rem;
      max-width: 24rem;
      padding: 0;
    }
  }

  .savebutton {
    margin: 20px 0px 0px 30px;
  }

  .tittle {
    flex-grow: 0;
    flex-shrink: 0;
    width: 15rem;
    padding-top: 1px;
  }

  .maintenance-button {
    right: -44px;
  }

  .radio {
    padding-top: 10px;
    margin-left: 20px;
    margin-bottom: 1px !important;
  }

  .ps-disclaimer {
    font-size: 10pt;
    font-style: italic;
  }

  .disclaimer-content {
    color: #6b6b6b;
    font-size: 10pt;
    font-weight: normal;
    margin-left: 35px;
  }

  a {
    color: $blue-2 !important;
  }

  .maintenance-buttons {
    display: inline-flex;
    gap: 1rem;    
    margin-top: 1rem;
  }

  .gridArea {
    height: 25rem;
    @include media-breakpoint-down(lg) {
      height: 15rem;
    }
  }

  input#active {
    margin-left: 0;
  }

  @media screen and (max-width: 900px) {
    .notificationContainer {
      max-width: 1020px !important;
      padding: 0px !important;
    }

    .lineLabel {
      margin: 0px;
      padding-top: 4px !important;
      font-weight: bolder !important;
    }

    .templateLabel {
      display: initial !important;
      margin-top: 5px;
      padding-top: 7px;
      padding-left: 10px;
    }

    .templateInput {
      margin-top: 5px;
    }

    .dateHead {
      margin-top: 10px;
      width: 183px;
    }

    .fromDate {
      padding-left: 0.1rem;
      padding-right: 0rem;
      max-width: 140px;
  }

    .templateHead {
      display: none;
    }

    .headers {
      justify-content: flex-start;
      gap: 3.5rem;
      div {
        width: 183px !important;
      }
    }
    .ddEventHead, .researchDocuments, .managerHead, .templateHead {
      width: 183px !important;
      padding: 0 !important;
    }

    .fullInput {
      padding-top: 0px;
      margin-left: 0px;
    }

    .savebutton {
      margin: 10px 0px 0px 20px;
    }

    .testGroup {
      .emailGroup{
        position: relative;
        left: 20px;
        .emailLabel {
          margin: 7px 0px 0px 5px;
        }
        .emailInput{
          max-width: 320px;
          margin-left: 25px;
        }
      }
    }
    .pl-2, .px-2 {
      padding-left: 2.1rem !important;
    }
  }
}